<template>
  <div>
    <div>
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-3">
            <div class="col-md-12">
              <h1 class="m-0 text-dark">Collegiate Links</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  <h2 class="card-title">Using Local Storage</h2>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <a
                        href="https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp"
                        class="btn btn-primary btn-blue btn-block"
                        target="_blank"
                      >
                        Team Captain Tools
                        <i class="fa fa-arrow-right ml-2"></i>
                      </a>
                      <a
                        href="https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List"
                        class="mt-3 btn btn-primary btn-blue btn-block"
                        target="_blank"
                      >
                        Individual Event Waivers
                        <i class="fa fa-arrow-right ml-2"></i>
                      </a>
                      <a
                        href="https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp"
                        class="mt-3 btn btn-primary btn-blue btn-block"
                        target="_blank"
                      >
                        Registrar Tools <i class="fa fa-arrow-right ml-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-header">
                  <h2 class="card-title">Using POST</h2>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <form
                        action="https://www.usawaterski.org/rankings/collegiate/ncwsa_rostermanager_usa.asp"
                        method="post"
                        target="_blank"
                      >
                        <input type="hidden" name="id" :value="ls.id" />
                        <input type="hidden" name="auth" :value="ls.auth" />
                        <button class="btn btn-primary btn-blue btn-block">
                          Team Captain Tools
                          <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                      </form>
                      <form
                        action="https://www.usawaterski.org/rankings/collegiate/ncwsa_waiver_usa.asp?FormStatus=List"
                        method="post"
                        target="_blank"
                        class="mt-3"
                      >
                        <input type="hidden" name="id" :value="ls.id" />
                        <input type="hidden" name="auth" :value="ls.auth" />
                        <button class="btn btn-primary btn-blue btn-block">
                          Individual Event Waivers
                          <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                      </form>
                      <form
                        action="https://www.usawaterski.org/rankings/collegiate/ncwsa_registrar_usa.asp"
                        method="post"
                        target="_blank"
                        class="mt-3"
                      >
                        <input type="hidden" name="id" :value="ls.id" />
                        <input type="hidden" name="auth" :value="ls.auth" />
                        <button class="btn btn-primary btn-blue btn-block">
                          Registrar Tools
                          <i class="fa fa-arrow-right ml-2"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ls: {
        id: localStorage.id,
        auth: localStorage.auth,
      },
    };
  },
};
</script>